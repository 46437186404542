import SEO from '@cuidardigital/commons/components/Seo/seo'
import { navigate } from 'gatsby'
import React, { useEffect } from 'react'

const NotFoundPage = () => {
	useEffect(() => {
		navigate('/')
	}, [])

	return (
		<>
			<SEO />
			<h1>404</h1>
		</>
	)
}

export default NotFoundPage
